import React, { Component } from 'react'
import { withRouter } from 'react-router'
import FixedSideBar from './FixedSideBar.jsx'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Currency from '../../Common/Currency.jsx'
import { Modal } from 'react-bootstrap'
import * as PackagesActions from '../Actions/Packages.jsx'
import * as OrderActions from '../Actions/Order.jsx'
import * as OfficeActions from '../Actions/Office.jsx'
import * as CalendarActions from '../Actions/Calendar.jsx'
import NextButton from './NextButton.jsx'
import BackButton from './BackButton.jsx'
import Container from './Container.jsx'
import request from 'superagent'
import moment from 'moment'
import Utils from '../../Common/Utils.jsx'
import { setNavPackages, setNavLoading } from '../Actions/Nav.jsx'
import Coupon from './Coupon.jsx'
import { PackageAppointmentList } from './Calendar.jsx'
import { canSetupOnExistingState, canSetupOnPageLoad, getAnalyticsEventLabel } from '../util.jsx'
import { PopUpModal } from './PopUpModal.jsx'
import './../osstyle.css';

const DescriptionView = (props) => (
    <div className="service-description">
        {props.description && props.description !== '' && <p dangerouslySetInnerHTML={{ __html: props.description }}/>}
        {props.children ?? null}
    </div>
);

export function serviceExists(service, services) {
    return (services || []).indexOf(service.id) !== -1;
}

export function serviceInPackages(service, orderPackages, packages) {
    for (let i = 0, l = orderPackages.length; i < l; i++) {
        let _package = packages.find((_package) => {
            return _package.id === orderPackages[i];
        });
        if (_package.services.find((_service) => { return service.id === _service.id; })) {
            return true;
        }
    }
    return false;
}


export function getPackageServices(orderPackages, packages) {
    for (let i = 0, l = orderPackages.length; i < l; i++) {
        let _package = packages.find((_package) => {
            return _package.id === orderPackages[i];
        });
        return _package && _package.hasOwnProperty('services') ? _package.services : null
    }
    return null;
}

export function serviceInPackage(serviceId, _package) {
    if (typeof _package !== 'object' || !_package) return false;
    for (let i = 0, l = _package.services.length; i < l; i++) {
        if (_package.services[i].id === serviceId) {
            return true;
        }
    }
    return false;
}

export class TaxView extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const total = this.props.taxes.reduce((total, item) => {
            return total + item.total;
        }, 0);

        return total > 0
            ? <ul style={{marginBottom:'0'}}>
                <li>Tax: <Currency amount={total}/></li>
            </ul>
            : null;
    }

}

export class PackageServicesView extends Component {

    constructor(props) {
        super(props);
    }

    getServiceSubTotalBeforeDiscounts(service)
    {
        let subtotal = 0;
        if (service) {
            for (let x = 0, y = service.fees.length; x < y; x++) {
                subtotal += service.fees[x].gross;
            }
        }
        return subtotal;
    }

    render() {
        return this.props.services.length > 0
            ? <div>
                <span style={{ fontWeight: "400", fontSize: "16px" }}>Included Services:</span>
                <ul style={{margin:'10px 0', fontSize: '16px', fontWeight: '400'}}>
                    {this.props.services.map((service, key) => {
                        return <li key={key}>
                                {service.name}{!this.props.hideFees && <span>&nbsp;&middot;&nbsp;<Currency amount={this.getServiceSubTotalBeforeDiscounts(service)}/></span>}
                        </li>
                    })}
                    {this.props.children}
                </ul>
            </div>
            : null;
    }

}

export class PackagesView extends Component {

    constructor(props) {
        super(props);

        this.packageExists = this.packageExists.bind(this);
        this.getPackage = this.getPackage.bind(this);
    }

    packageExists(_package) {
        return (this.props.currentPackages || []).find((__package) => {
            return __package.id === _package.id;
        });
    }

    getPackage(id) {
        return this.props.packages.find((_package) => {
            return _package.id === id;
        });
    }

    orderPrimaryServiceCount(includePackageServices = true) {
        const primaryServicesLength = (this.props.pricingServices || []).filter(
          service => service.hasOwnProperty('ancillary') && service['ancillary'] === false
        ).length;

        if(!includePackageServices){
            return primaryServicesLength
        }
        // Filter through Package Services to find additional primary services
        const packageServices = getPackageServices(this.props.currentPackages, this.props.packages)
        // Find Services from the props.currentServices

        // console.debug('packages view packageServices', packageServices)
        const primaryServicesFromPackagesLength = (packageServices || []).filter(service => {
            return service.hasOwnProperty('ancillary') && service['ancillary'] === false
        }).length;
        // console.debug('packages view lengths', primaryServicesLength, primaryServicesFromPackagesLength)
        return primaryServicesLength + primaryServicesFromPackagesLength
    }

    orderAncillaryServiceCount() {
        return (this.props.pricingServices || []).filter(
          service => service.hasOwnProperty('ancillary') && service['ancillary'] === true
        ).length;
    }

    orderHasAncillaryService() {
        return this.orderAncillaryServiceCount() > 0;
    }

    render() {

        const currentPackage = this.props.currentPackages.length > 0 ? this.props.packages.find((_package) => {
            return _package.id === this.props.currentPackages[0];
        }) : null;

        return (
            <div>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                <div className="row row-positive" style={{paddingBottom:'5px',marginTop:'20px'}}>
                    <div className="col-md-12">
                        <h4 className="text-center" style={{ fontSize: "20px", fontWeight: "600", lineHeight: "20px", fontFamily: "Source Sans Pro" }}>Select a package</h4>
                    </div>
                </div>
                <div className="row row-positive">
                    <div className="col-md-12" style={{padding:'0'}}>
                        {!currentPackage && <ul className="packages">
                            {this.props.packages.map((_package, key) => {
                                return (
                                    <li className="package package-container" key={key}>
                                        <div className="package-conatiner-head">
                                        <div className="package-card">
                                            {/* Package Name */}
                                            <h4 className="package-title-style" style={{ width: "auto", display: "inline-flex" }}>
                                                <span dangerouslySetInnerHTML={{__html: _package.name}}/>
                                                {_package.total > 0 && !this.props.hideFees && <small>&nbsp;&middot;&nbsp;<Currency amount={this.props.hideTaxes ? _package.subtotal : _package.total}/></small>}
                                            </h4>
                                                {/* Best offer */}
                                            {_package.best && <div className="offer-tag">
                                                <span className="material-symbols-outlined">auto_awesome</span>
                                                <div className="offer-name">{_package.bestText ? _package.bestText : 'Best Offer!'}</div>
                                            </div>}
                                        </div>
                                        {/* Description */}
                                        <DescriptionView description={_package.description}/>

                                        {/* Package Services */}
                                        <PackageServicesView services={_package.services} hideFees={this.props.hideFees} hideTaxes={this.props.hideTaxes}>
                                            {_package.savings > 0 && !this.props.hideFees && <li>
                                                <Currency amount={_package.savings}/> in savings
                                            </li>}
                                        </PackageServicesView>
                                        </div>
                                        <div className={''}>
                                                {/*  pull-right*/}
                                                <button type="button" className="btn btn-primary btn-xs add-package-btn select-package-button"
                                                        style={{display:'block',margin:'0 auto'}}
                                                        onClick={() => {
                                                            this.props.addPackage(_package)
                                                        }}
                                                        disabled={this.props.addingPackage}>
                                                    {/* {this.props.addingPackage &&
                                                    <div className="text-center"><i className={'fas fa-spinner-third fa-spin fa-2x'}/></div>}
                                                    {!this.props.addingPackage &&
                                                    <span><i className="fa fa-plus"/> Add Package</span>} */}
                                                    SELECT PACKAGE
                                                </button>
                                                {/* <div className={'star'}/> */}
                                            </div>
                                    </li>
                                );
                            })}
                        </ul>}

                        {currentPackage && <ul className="packages">
                            {/* <h3>Thank you!</h3>
                            <p>You've chosen our <strong dangerouslySetInnerHTML={{__html: currentPackage.name}}/> package.</p> */}
                            {
                                [{...currentPackage}].map((_package, key) =>  {
                                    return (
                                        <div className={`${ app_state.pac_enabled ? !this.props.hasPacSelected() ? "package-border-line" : this.props.hasPacSelected() ? "package-border-line" : "" : currentPackage.rer && this.props.rerRushable ? "package-border-line" : ""}`}>
                                            <li className={`${ app_state.pac_enabled ? this.props.hasPacSelected() && currentPackage.rer && this.props.rerRushable ? "package-group-pac-and-rer package-color-rer" : !this.props.hasPacSelected() && currentPackage.rer && this.props.rerRushable ? "package-group-rer-and-pac package-group-pac-and-rer package-color-rer" : this.props.hasPacSelected() ? "package-group-pac package-color-pac" : !this.props.hasPacSelected() ? "package-color-pac package-group-pac" : "package package-color" : currentPackage.rer && this.props.rerRushable ? "package-group-services package-color-rer" : "package package-color"  } package-container`} key={key}>
                                            <div>
                                                <h4>
                                                    <span dangerouslySetInnerHTML={{__html: _package.name}}/>
                                                    {_package.total > 0 && !this.props.hideFees && <small>&nbsp;&middot;&nbsp;<Currency amount={this.props.hideTaxes ? _package.subtotal : _package.total}/></small>}

                                                    {_package.best && <div className="offer-tag">
                                                        <span className="material-symbols-outlined">auto_awesome</span>
                                                        <div className="offer-name">{_package.bestText ? _package.bestText : 'Best Offer!'}</div>
                                                    </div>}
                                                </h4>

                                                <DescriptionView description={_package.description}/>

                                                <PackageServicesView services={_package.services} hideFees={this.props.hideFees} hideTaxes={this.props.hideTaxes}>
                                                    {_package.savings > 0 && !this.props.hideFees && <li>
                                                        <Currency amount={_package.savings}/> in savings
                                                    </li>}
                                                </PackageServicesView>
                                                </div>
                                                <div className={''}>
                                                    {/* <div style={{fontStyle:'italic',fontWeight:'bold'}}>{_package.bestText ? _package.bestText : 'Best Offer!'}</div> */}
                                                    <button type="button" className="btn btn-primary btn-xs remove-package-btn select-package-button"
                                                            style={{display:'block',margin:'0 auto'}}
                                                            onClick={() => {
                                                                const settingRequirePrimaryService = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_REQUIRE_PRIMARY_SERVICE_BEFORE_ANCILLARY')
                                                                const singlePrimaryServices = this.orderPrimaryServiceCount() < 2
                                                                /*
                                                                * We need to prevent removing a primary service for these conditions:
                                                                * - the office settings require a primary service to be selected before selecting ancillary services
                                                                * - the order has ancillary services and only one primary service
                                                                * - this service being removed is a primary service
                                                                */
                                                                if(settingRequirePrimaryService && singlePrimaryServices && this.orderHasAncillaryService()){
                                                                    alert('At least one primary service is required. Please remove any ancillary services before removing this package.')
                                                                    return
                                                                }

                                                                this.props.removePackage(currentPackage)
                                                            }}
                                                            disabled={this.props.addingPackage}>
                                                        {/* {this.props.addingPackage &&
                                                        <div className="text-center"><i className={'fas fa-spinner-third fa-spin fa-2x'}/></div>}
                                                        {!this.props.addingPackage &&
                                                        <span><i className="fa fa-plus"/> Add Package</span>} */}
                                                        <span>REMOVE PACKAGE</span>
                                                    </button>

                                                    {/* <div className="offer-tag">
                                                        <span className="material-symbols-outlined">auto_awesome</span>
                                                        <div className="offer-name">{_package.bestText ? _package.bestText : 'Best Offer!'}</div>
                                                    </div> */}
                                                </div>
                                            </li>
                                            {currentPackage.rer && this.props.rerRushable && <div className={`package-rer ${this.props.rushRer ? "package-rer-color" : ""} ${app_state.pac_enabled ? "package-group-rer-and-pac" : ""}`}>
                                                <div className="package-rer-card">
                                                    <div className="package-rer-card-icon"><span class="material-symbols-outlined acute" style={{ color: "#0F75BC", fontSize: "38px" }}>acute</span></div>
                                                    <h4>
                                                        {/* <i className={'fas fa-clock text-info'} style={{fontSize:'1.5em',verticalAlign:'middle'}}/> */}
                                                        &nbsp;Do you want to rush RER's delivery? ·
                                                        {/* <small>&nbsp;&middot;&nbsp;<Currency amount={this.props.rerRushAmount}/></small> */}
                                                    </h4>
                                                    <div className="text-center pull-right">
                                                        {this.props.rushRer && <button type="button" className="btn btn-warning btn-xs package-rer-remove-btn"
                                                                                    onClick={this.props.removeRushRer}
                                                                                    disabled={this.props.addingRushRer}>
                                                            <span>
                                                                {/* <i className="fa fa-remove"/>  */}
                                                                Remove Rush
                                                            </span>
                                                        </button>}
                                                        {!this.props.rushRer && <button type="button" className="btn btn-primary btn-xs package-rer-add-btn"
                                                                                        onClick={this.props.addRushRer}
                                                                                        disabled={this.props.addingRushRer}>
                                                            <span>
                                                                {/* <i className="fa fa-plus"/>  */}
                                                                Rush the Report
                                                            </span>
                                                        </button>}
                                                    </div>
                                                </div>
                                                <h3 className="package-rer-card-price"><Currency amount={this.props.rerRushAmount}/></h3>
                                                <DescriptionView description={'Your package includes a Repair Estimate Report, which is typically delivered within 36-48 hours after your inspection is completed. If you need it sooner, you can add rush delivery - guaranteed to get you the report within 24 hours!'}/>
                                            </div>}
                                            {app_state.pac_enabled && !this.props.settings.getSettingBool('PAC_HIDE_ON_ONLINE_SCHEDULER') ? (
                                                this.props.hasPacSelected() ? (
                                                    <div className={`package-container service-pac ${this.props.hasPacSelected() ? "package-pac-color" : ""}`}>
                                                        <div className="package-pac-card">
                                                            <div style={{display: 'flex'}}>
                                                                <div className="package-pac-card-icon"><span class="material-symbols-outlined acute" style={{ color: "#8551E1", fontSize: "28px" }}>real_estate_agent</span></div>
                                                                <h4>
                                                                    Your inspection qualifies you to Pay at Close!
                                                                </h4>
                                                            </div>
                                                            <DescriptionView description={'You have chosen to pay for your home inspection when you close on your home. This means you HAVE NOT paid for your inspection yet but will pay for it during the closing process.'}/>
                                                        </div>
                                                            <div className="">
                                                                <button type="button" className="btn btn-warning btn-xs package-pac-remove-btn select-package-button"
                                                                                            onClick={this.props.canclePayAtClose}>
                                                                    <span>
                                                                        {/* <i className="fa fa-remove"/>  */}
                                                                        Remove Pay At Close
                                                                    </span>
                                                                </button>
                                                            </div>
                                                    </div>
                                                ) : (
                                                    (this.props.isPacEligible() && !this.props.settings.getSettingBool('PAC_HIDE_ON_ONLINE_SCHEDULER')) ? (
                                                        <div className={`package-container service-pac ${this.props.hasPacSelected() ? "package-pac-color" : ""}`}>
                                                            <div className="package-pac-card">
                                                                <div style={{display: 'flex'}}>
                                                                    <div className="package-pac-card-icon"><span class="material-symbols-outlined acute" style={{ color: "#8551E1", fontSize: "28px" }}>real_estate_agent</span></div>
                                                                    <h4>
                                                                        Your inspection qualifies you to Pay at Close!
                                                                    </h4>
                                                                </div>
                                                                <DescriptionView description={'This inspection qualifies you to pay for your home inspection at closing, allowing you to bundle the cost of your home inspection AND other add-on services alongside your closing costs.'}/>
                                                            </div>
                                                            <div className='pac-button-style-services'>
                                                                    <button type="button" className="btn btn-primary btn-xs package-pac-add-btn select-package-button"
                                                                                                    onClick={this.props.addPayAtClose}>
                                                                        <span>
                                                                            {/* <i className="fa fa-plus"/>  */}
                                                                            Add Pay At Close
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                        </div>
                                                    ) : null
                                                )
                                            ) : null}
                                        </div>
                                    )
                                })
                            }
                        </ul>}
                    </div>
                </div>
            </div>
        );
    }
}

export class ServicesView extends Component {

    constructor(props) {
        super(props);

        this.state = { service_state: false };
        this.serviceExists = this.serviceExists.bind(this);
        this.serviceInPackages = this.serviceInPackages.bind(this);
    }

    serviceExists(service) {
        return (this.props.currentServices || []).indexOf(service.id) !== -1;
    }

    orderPrimaryServiceCount(includePackageServices = true) {
        const primaryServicesLength = (this.props.pricingServices || []).filter(
          service => service.hasOwnProperty('ancillary') && service['ancillary'] === false && !service['is_pac']
        ).length;

        if(!includePackageServices){
            return primaryServicesLength
        }
        // Filter through Package Services to find additional primary services
        const packageServices = getPackageServices(this.props.currentPackages, this.props.packages)
        // Find Services from the props.currentServices

        // console.debug('packageServices', packageServices)
        const primaryServicesFromPackagesLength = (packageServices || []).filter(service => {
            return service.hasOwnProperty('ancillary') && service['ancillary'] === false
        }).length;
        // console.debug('lengths', primaryServicesLength, primaryServicesFromPackagesLength)
        return primaryServicesLength + primaryServicesFromPackagesLength
    }

    orderHasPrimaryService() {
        return this.orderPrimaryServiceCount() > 0;
    }

    orderAncillaryServiceCount() {
        return (this.props.pricingServices || []).filter(
          service => service.hasOwnProperty('ancillary') && service['ancillary'] === true
        ).length;
    }

    orderHasAncillaryService() {
        return this.orderAncillaryServiceCount() > 0;
    }

    serviceInPackages(service) {
        for (let i = 0, l = this.props.currentPackages.length; i < l; i++) {
            let _package = this.props.packages.find((_package) => {
                return _package.id === this.props.currentPackages[i];
            });
            if (_package.services.find((_service) => { return service.id === _service.id; })) {
                return true;
            }
        }
        return false;
    }

    render() {
        return (
            <div>
                <div className="row row-positive" style={{paddingBottom:'5px',marginTop:'20px'}}>
                    <div className="col-md-12">
                        <h4 className="text-center" style={{ fontSize: "20px", fontWeight: "600", lineHeight: "20px", fontFamily: "Source Sans Pro" }}>Add the services that you need</h4>
                    </div>
                </div>
                <div className="row row-positive">
                    <div className="col-md-12" style={{padding:'0'}}>
                        <ul className="services" style={{listStyleType:'none',margin:'0',padding:'0'}}>
                            {this.props.services.filter((service) => !service.is_pac).map((service, key) => {
                                return (
                                    <div className={`${ service.rer && this.props.rerRushable && !serviceInPackages(service, this.props.currentPackages, this.props.packages) && serviceExists(service, this.props.currentServices) ? "service-border-line" : ""}`} style={{ marginBottom: "8px" }}>
                                        <li className={  service.label && service.rer && this.props.rerRushable && !serviceInPackages(service, this.props.currentPackages, this.props.packages) && serviceExists(service, this.props.currentServices) ? "service-label-group" : service.label ? "" : `${service.rer && this.props.rerRushable && !serviceInPackages(service, this.props.currentPackages, this.props.packages) && serviceExists(service, this.props.currentServices) ? "service-group" : "service"} ${!serviceExists(service, this.props.currentServices) ? "" : `${!serviceInPackages(service, this.props.currentPackages, this.props.packages) && service.rer && this.props.rerRushable && !serviceInPackages(service, this.props.currentPackages, this.props.packages) && serviceExists(service, this.props.currentServices) ? "service-color-group" : !serviceInPackages(service, this.props.currentPackages, this.props.packages) ? "service-color service-color-border" : ""}`} package-container`} key={key}>
                                            <div className='service-card-container'>

                                            {service.label
                                                ? <div className="text-center"><h4  className='services-labels-style' dangerouslySetInnerHTML={{__html: service.name}}/></div>
                                                : <div>
                                                    <div className="service-card">
                                                        <h4 style={{ width: `${(this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_REQUIRE_PRIMARY_SERVICE_BEFORE_ANCILLARY') &&
                                                                            service.ancillary &&
                                                                            !this.orderHasPrimaryService()) ? "170px" : "100%"}` }}>
                                                            <span dangerouslySetInnerHTML={{__html: service.name}}/>
                                                            {service.total > 0 && !this.props.hideFees && <small>&nbsp;&middot;&nbsp;<Currency amount={this.props.hideTaxes ? service.subtotal : service.total}/></small>}
                                                        </h4>

                                                    </div>

                                                </div>
                                            }
                                                    <DescriptionView description={service.description}>
                                                        {service.savings > 0 && !this.props.hideFees && <div>
                                                            <Currency amount={service.savings}/> in savings
                                                        </div>}
                                                    </DescriptionView>
                                            </div>
                                                    <div className="">
                                                            {
                                                                serviceInPackages(service, this.props.currentPackages, this.props.packages)
                                                                ?
                                                                <div>
                                                                    <button type="button" className="btn btn-info btn-xs in-package-btn select-package-button" disabled="disabled">
                                                                        {/* <i className="fa fa-check"/>  */}
                                                                        In Package
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div>
                                                                    {
                                                                        serviceExists(service, this.props.currentServices) && <button type="button" className="btn btn-warning btn-xs remove-package-btn select-package-button"
                                                                                                                onClick={() => {
                                                                                                                    const settingRequirePrimaryService = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_REQUIRE_PRIMARY_SERVICE_BEFORE_ANCILLARY')
                                                                                                                    const singlePrimaryServices = this.orderPrimaryServiceCount() < 2
                                                                                                                    /*
                                                                                                                    * We need to prevent removing a primary service for these conditions:
                                                                                                                    * - the office settings require a primary service to be selected before selecting ancillary services
                                                                                                                    * - the order has ancillary services and only one primary service
                                                                                                                    * - this service being removed is a primary service
                                                                                                                    */
                                                                                                                    if(settingRequirePrimaryService && singlePrimaryServices && this.orderHasAncillaryService() && !service.ancillary){
                                                                                                                        alert('At least one primary service is required. Please remove any ancillary services before removing this service.')
                                                                                                                        return
                                                                                                                    }

                                                                                                                    this.props.removeService(service)
                                                                                                                }}
                                                                                                                disabled={this.props.addingService}>
                                                                            <span>
                                                                                {/* <i className="fa fa-remove"/>  */}
                                                                                Remove Service
                                                                            </span>
                                                                        </button>
                                                                    }
                                                                    {
                                                                        !serviceExists(service, this.props.currentServices) && !service?.label && <button type="button" className="btn btn-primary btn-xs add-package-btn select-package-button"
                                                                                                                onClick={() => {
                                                                                                                    this.props.addService(service)
                                                                                                                }}
                                                                                                                disabled={
                                                                                                                    (
                                                                                                                        this.props.addingService ||
                                                                                                                        (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_ALLOW_ONLY_SINGLE_SERVICE') &&
                                                                                                                            !service.ancillary &&
                                                                                                                            this.orderHasPrimaryService() === true) ||
                                                                                                                        (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_REQUIRE_PRIMARY_SERVICE_BEFORE_ANCILLARY') &&
                                                                                                                            service.ancillary &&
                                                                                                                            this.orderHasPrimaryService() === false
                                                                                                                        )
                                                                                                                    )
                                                                                                                }>
                                                                            {
                                                                            (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_REQUIRE_PRIMARY_SERVICE_BEFORE_ANCILLARY') &&
                                                                            service.ancillary &&
                                                                            !this.orderHasPrimaryService()) ?

                                                                                    <span>
                                                                                        {/* <i className="fa fa-plus"/>  */}
                                                                                        Please add a 'primary' service to your cart first
                                                                                    </span>

                                                                                :

                                                                                    <span>
                                                                                        {/* <i className="fa fa-plus"/>  */}
                                                                                        Add Service
                                                                                    </span>

                                                                            }
                                                                        </button>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                        </li>
                                        {service.rer && this.props.rerRushable && !serviceInPackages(service, this.props.currentPackages, this.props.packages) && serviceExists(service, this.props.currentServices) && <div className={`package-rer ${this.props.rushRer ? "package-rer-color" : ""}`}>
                                            <div className="package-rer-card package-rer-container" style={{display: 'flex'}}>
                                                <div className='rer-rush-card-container'>
                                                <h4 style={{position: 'relative', top: '0px' }}>
                                                    <span class="material-symbols-outlined acute-black" style={{ color: "#0F75BC", fontSize: "28px", position: 'relative', top: '8px' }}>acute</span>
                                                    &nbsp;Repair Estimate Report Rush Delivery<small>&nbsp;&middot;&nbsp;<Currency amount={this.props.rerRushAmount}/></small>
                                                </h4>
                                                <DescriptionView description={'Get the Repair Estimate Report in 24 hours, guaranteed! Normal delivery is 36-48 hours after your inspection is done.'}/>
                                                </div>
                                                <div>
                                                    {this.props.rushRer && <button type="button" className="btn btn-warning btn-xs package-rer-remove-btn select-package-button"
                                                                                style={{marginTop:"28px"}}
                                                                                onClick={this.props.removeRushRer}
                                                                                disabled={this.props.addingRushRer}>
                                                        <span>
                                                            {/* <i className="fa fa-remove"/>  */}
                                                            Remove Rush
                                                        </span>
                                                    </button>}
                                                    {!this.props.rushRer && <button type="button" className="btn btn-primary btn-xs package-rer-add-btn select-package-button"
                                                                                    style={{marginTop: "11px"}}
                                                                                    onClick={this.props.addRushRer}
                                                                                    disabled={this.props.addingRushRer}>
                                                        <span>
                                                            {/* <i className="fa fa-plus"/>  */}
                                                            Rush the Report
                                                        </span>
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                );
                            })}
                            {app_state.pac_enabled && !this.props.settings.getSettingBool('PAC_HIDE_ON_ONLINE_SCHEDULER') ? (
                                this.props.isPacEligible() ? (
                                    <div className={`package-container service-pac ${this.props.hasPacSelected() ? "package-pac-color" : ""}`}>
                                        <div className="package-pac-card">
                                            <div style={{display: 'flex'}}>
                                                <div className="package-pac-card-icon"><span class="material-symbols-outlined acute" style={{ color: "#8551E1", fontSize: "28px" }}>real_estate_agent</span></div>
                                                <h4>
                                                    Your inspection qualifies you to Pay at Close!
                                                </h4>
                                            </div>
                                            <DescriptionView description={'You have chosen to pay for your home inspection when you close on your home. This means you HAVE NOT paid for your inspection yet but will pay for it during the closing process.'}/>
                                        </div>
                                        {this.props.hasPacSelected() ? (
                                            <div className="">
                                                <button type="button" className="btn btn-warning btn-xs package-pac-remove-btn select-package-button"
                                                                            onClick={this.props.canclePayAtClose}>
                                                    <span>
                                                        {/* <i className="fa fa-remove"/>  */}
                                                        Remove Pay At Close
                                                    </span>
                                                </button>
                                            </div>
                                        ) : (
                                            <div className='pac-button-style-services'>
                                                <button type="button" className="btn btn-primary btn-xs package-pac-add-btn select-package-button"
                                                                                onClick={this.props.addPayAtClose}>
                                                    <span>
                                                        {/* <i className="fa fa-plus"/>  */}
                                                        Add Pay At Close
                                                    </span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ) : null
                            ) : null}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export class AdditionalFeesView extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const additionalFees = this.props.additional.fees.filter((fee) => { return parseFloat(fee.value) !== 0.00; });

        return (
            additionalFees.length > 0 ?
                <div>
                    <div className="row row-positive" style={{borderBottom:'1px solid #ccc',paddingBottom:'5px',marginTop:'20px'}}>
                        <div className="col-md-12">
                            <h4 className="text-center">Additional Charges</h4>
                        </div>
                    </div>
                    <div className="row row-positive">
                        <div className="col-md-12" style={{padding:'0'}}>
                            <ul className="services" style={{listStyleType:'none',margin:'0',padding:'0'}}>
                                {additionalFees.map((fee, key) => {
                                    return (
                                        <li className={'service'} key={key}  style={{fontSize: "16px",fontWeight: "600",lineHeight: "20px",fontFamily: "Source Sans Pro"}}>
                                            {fee.description}
                                            <small>&nbsp;&middot;&nbsp;<Currency amount={fee.value}/></small>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                :
                null
        );
    }
}

export class PacConfirmationModal extends Component {
    render() {
        const {onCancel, onConfirm} = this.props;

        return <Modal show={true} onHide={onCancel} bsSize="lg" className="appointment-substitute-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Quick Reminder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '5px 10px' }}>
                <div className={'mb-3'}>
                    This inspection qualifies you to <b>FlexFund</b>! While it's certainly not required, we just
                    wanted to remind you that it can totally make things easier on your end to bundle your inspection fees into your
                    home closing.
                </div>
                <div>
                    You can activate it by navigating back and opting-in toward the top of the page.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-primary"
                                onClick={onCancel}>Go back</button>

                        <button type="button" className="btn btn-default"
                                onClick={onConfirm}>Continue anyway</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }
}

/**
 *
 */
// Modal we show whenever the validation api fails giving the user other appointments of inspectors
export class PackageServiceInspectorAppointmentConflictModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Modal show={true} onHide={this.props.close} bsSize="lg" className="appointment-substitute-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Sorry!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '5px 10px' }}>
                <p>The time at <strong>{moment(this.props.datetime).format('M/D/YYYY [at] h:mmA')}</strong> has a conflict with the inspectors set to perform your inspection.</p>
                {this.props.loading && <div className="text-center"><i className={'fas fa-spinner-third fa-spin fa-2x'}/></div>}
                {!this.props.loading && this.props.appointments.length > 0 && <div>
                    <p>Please select another appointment:</p>
                    <PackageAppointmentList shortView={false}
                                     services={this.props.services}
                                     showInspectorNames={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES')}
                                     appointments={this.props.appointments}
                                     selectAppointment={this.props.selectAppointment}
                                     users={this.props.users}/>
                </div>}
                {!this.props.loading && this.props.appointments.length === 0 && <div>
                    <p>We're sorry. There are no other appointments available for your service requirements.
                        Please call our office at <a href={"tel:"+app_state.office_phone}>{app_state.office_phone}</a> for
                        help scheduling your inspection.</p>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <div className="pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default"
                                onClick={this.props.close}>Close</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }
}

/**
 *
 */
export class PackageOfferingForTwoOrMoreServicesModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Modal show={true} onHide={this.props.close} bsSize="sm" className="package-offering-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Package Offers
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '5px 10px' }}>
                <p>It looks like you've added services that you can get in {this.props.packages.length > 1 ? 'one or more' : 'one'} of our packages:</p>
                <ul style={{listStyleType:'none',margin:'0',padding:'0'}} className="packages">
                    {this.props.packages.map((_package, key) => {
                        return (
                            <li className={'package'} key={key}>

                                {_package.best && <div className={'pull-right text-center'}>
                                    <div className={'star'}/>
                                    <div style={{fontStyle:'italic',fontWeight:'bold'}}>{_package.bestText ? _package.bestText : 'Best Offer!'}</div>
                                </div>}
                                <h4>
                                    {_package.name}
                                    &nbsp;&nbsp;{!this.props.hideFees && <small><Currency amount={_package.total}/></small>}
                                </h4>

                                <PackageServicesView services={_package.services} hideFees={this.props.hideFees}>
                                    {_package.savings > 0 && !this.props.hideFees && <li>
                                        Save <strong><Currency amount={_package.savings}/></strong> over your current selection!
                                    </li>}
                                </PackageServicesView>

                                <div className="text-center">
                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => { this.props.addPackage(_package); this.props.close(); }}>
                                        <i className="fa fa-plus"/> Add Package
                                    </button>
                                </div>

                            </li>
                        )
                    })}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <div className="text-center">
                    <button type="button" className="btn btn-default"
                            onClick={this.props.close}>No thanks</button>
                </div>
            </Modal.Footer>
        </Modal>;
    }
}

export class PackagesContainer extends Component  {

    constructor(props) {
        super(props);

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.save = this.save.bind(this);
        this.loadPackages = this.loadPackages.bind(this);
        this.loadServices = this.loadServices.bind(this);
        this.addPackage = this.addPackage.bind(this);
        this.removePackage = this.removePackage.bind(this);
        this.addService = this.addService.bind(this);
        this.removeService = this.removeService.bind(this);
        this.getService = this.getService.bind(this);
        this.selectAppointment = this.selectAppointment.bind(this);
        this.checkCrossServicePackageOfferings = this.checkCrossServicePackageOfferings.bind(this);
        this.setup = this.setup.bind(this);
        this.addRushRER = this.addRushRER.bind(this);
        this.removeRushRER = this.removeRushRER.bind(this);
        this.hasPacSelected = this.hasPacSelected.bind(this);
        this.addPayAtClose = this.addPayAtClose.bind(this);
        this.cancelPayAtClose = this.cancelPayAtClose.bind(this);
        this.isPacEligible = this.isPacEligible.bind(this);
        this.verifyPacEligibility = this.verifyPacEligibility.bind(this);

        this.state = {
            loading: true,
            saving: false,
            adding_package: false,
            adding_service: false,
            packages: [],
            services: [],
            conflict_modal: false,
            inspector_conflict_modal: false,
            appointment_not_available_modal: false,
            substitute_appointments: [],
            package_offering_modal: false,
            rer_rush_modal: false,
            adding_rush_rer: false,
            packages_to_offer: [],
            setup: false,
            pac_confirmation: false,
            showModal: false,
            isMobile: window.innerWidth < 800,
        };
    }

    prev() {
        if (this.props.order.controls.length > 0) {
            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/property-info?t=' + this.props.token + '&office=' + this.props.office_uuid);
        } else {
            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/contacts?t=' + this.props.token + '&office=' + this.props.office_uuid);
        }
    }

    next() {
        if (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST')) {
            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/calendar?t=' + this.props.token + '&office=' + this.props.office_uuid);
        }
        // else {
        //     this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/review?t=' + this.props.token + '&office=' + this.props.office_uuid);
        // }
    }

    save() {
        this.props.setNavLoading(true);
        this.setState({saving:true});

        let start = moment(this.props.order.datetime);
        let end = start.clone();
        end.add(this.props.order.duration, "minutes");
        // validate that we have enough inspectors to satisfy the service requirements
        const limit = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT')
            ? this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT_VALUE')
            : 0;
        const inspectors = this.props.order.inspectors.map((inspector) => {
          return inspector.id;
        });

        if (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST')) {
            if (this.props.order.datetime && this.props.calendar.services_changed) {
                // if the package/service selection changed we must unselect the appointment and inspectors
                // this is so the user must reselect an appointment under the new service selection
                this.props.calendarActions.setSelectedAppointment(null);
                this.props.orderActions.setDatetime(null);
                this.props.orderActions.setInspectors([]);
                this.props.calendarActions.save()
                    .then(() => {
                        this.setState({saving:false}, this.next);
                    });
            } else {
                this.props.packageActions.save()
                    .then(() => {
                        this.setState({saving: false}, this.next);
                    });
            }
        } else if (this.props.order.inspectors.length > 0) {
            this.props.calendarActions
                .validateInspectorServices(limit,
                    this.props.order.duration,
                    this.props.order.inspectors.map((inspector) => { return inspector.id }),
                    this.props.order.services,
                    this.props.order.postal_code
                )
                .then((res) => {
                    if (res.status === 'ok') {
                        if (res.hasOwnProperty('added_inspectors')) {
                            this.props.orderActions
                                .setInspectors(
                                    res.added_inspectors.map((id) => {
                                        return {
                                            id,
                                            requested: false
                                        }
                                    }));
                            this.props.packageActions.save()
                                .then(() => {
                                    this.setState({saving:false}, this.next);
                                });
                        } else {
                            this.setState({saving:false}, this.next);
                        }

                        this.props.calendarActions
                            .isTimeAvailable(
                                start,
                                end,
                                this.props.order.duration,
                                limit,
                                inspectors,
                                this.props.order.services,
                                this.props.order.postal_code
                            )
                            .then((res) => {
                                if (!this.props.calendar.time_available) {
                                    this.props.setNavLoading(false);
                                    this.setState({
                                        appointment_not_available_modal:true,
                                        saving:false,
                                        substitute_appointments: res.hasOwnProperty('appointments') ? res.appointments : []
                                    })
                                    if (window.ga) {
                                        window.ga("send", {
                                            hitType: "event",
                                            eventCategory: "Online Scheduler",
                                            eventAction: "appointment-unavailable",
                                            eventLabel: getAnalyticsEventLabel(
                                                this.props.company_key,
                                                "The chosen appointment was no longer available"
                                            ),
                                        });
                                    }
                                } else {
                                    this.setState({ saving: false }, this.next);
                                }
                            });
                    } else {
                        this.props.setNavLoading(false);
                        this.setState({
                            inspector_conflict_modal:true,
                            saving:false,
                            substitute_appointments: res.hasOwnProperty('appointments') ? res.appointments : []
                        })
                        if (window.ga) {
                            window.ga('send', {
                                hitType: 'event',
                                eventCategory: 'Online Scheduler',
                                eventAction: 'inspector-conflict',
                                eventLabel: getAnalyticsEventLabel(this.props.company_key, 'There was a scheduling conflict found in the selected appointment')
                            });
                        }
                    }
                });

        } else {
            this.setState({saving:false}, this.next);
        }
    }

    loadPackages() {
        return request.get('/'+this.props.company_key+'/json/online_scheduler/packages')
            .set('X-Auth-Token', this.props.token)
            .then((res) => {
                this.setState({packages:res.body});
            });
    }

    loadServices() {
        return request.get('/'+this.props.company_key+'/json/online_scheduler/services')
            .set('X-Auth-Token', this.props.token)
            .then((res) => {
                this.setState({services:res.body});
            });
    }

    addPackage(_package, pacRemovedBecauseDisqualified = false) {
        const { addPackage } = this.props.packageActions;
        this.props.orderActions.setPricingLoading(true);
        this.setState({adding_package:true});
        addPackage(_package)
            .then(() => {
                return this.props.packageActions.loadPricing(pacRemovedBecauseDisqualified);
            })
            .then(this.verifyPacEligibility)
            .then(() => {
                this.setState({adding_package:false});
            });
        if (window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'Online Scheduler',
                eventAction: 'added-package',
                eventLabel: getAnalyticsEventLabel(this.props.company_key, _package.name)
            });
        }
        if (this.props.order.datetime) {
            this.props.calendarActions.setServicesChanged(true);
        }
    }

    removePackage(_package, pacRemovedBecauseDisqualified = false) {
        const { removePackage } = this.props.packageActions;
        this.props.orderActions.setPricingLoading(true);
        removePackage(_package)
            .then(() => {
                return new Promise((resolve, reject) => {
                    if (_package.rer && this.props.order.rush_rer) {
                        return this.removeRushRER();
                    } else {
                        return resolve();
                    }
                })
            })
            .then(() => {
                return this.props.packageActions.loadPricing(pacRemovedBecauseDisqualified);
            })
            .then(this.verifyPacEligibility);
        if (window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'Online Scheduler',
                eventAction: 'removed-package',
                eventLabel: getAnalyticsEventLabel(this.props.company_key, _package.name)
            });
        }
        if (this.props.order.datetime) {
            this.props.calendarActions.setServicesChanged(true);
        }
    }

    addService(service, pacRemovedBecauseDisqualified = false) {
        const { addService } = this.props.packageActions;
        this.props.orderActions.setPricingLoading(true);
        this.setState({adding_service:true});
        addService(service)
            .then(() => {
                return this.props.packageActions.loadPricing(pacRemovedBecauseDisqualified);
            })
            .then(this.verifyPacEligibility)
            .then(() => {
                this.setState({
                    adding_service:false
                }, this.checkCrossServicePackageOfferings);
            });
        if (window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'Online Scheduler',
                eventAction: 'added-service',
                eventLabel: getAnalyticsEventLabel(this.props.company_key, service.name)
            });
        }
        if (this.props.order.datetime) {
            this.props.calendarActions.setServicesChanged(true);
        }
    }

    removeService(service, pacRemovedBecauseDisqualified = false) {
        const { removeService } = this.props.packageActions;
        this.props.orderActions.setPricingLoading(true);
        removeService(service)
            .then(() => {
                return new Promise((resolve, reject) => {
                    if (service.rer && this.props.order.rush_rer) {
                        return this.removeRushRER();
                    } else {
                        return resolve();
                    }
                })
            })
            .then(() => {
                return this.props.packageActions.loadPricing(pacRemovedBecauseDisqualified);
            })
            .then(this.verifyPacEligibility)
        if (this.props.order.datetime) {
            this.props.calendarActions.setServicesChanged(true);
        }
        if (window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'Online Scheduler',
                eventAction: 'removed-service',
                eventLabel: getAnalyticsEventLabel(this.props.company_key, service.name)
            });
        }
    }

    checkCrossServicePackageOfferings() {
        // check for package offerings if we don't already have a package
        if (this.props.order.packages.length === 0 && !this.state.adding_package) {
            let packages_to_offer = this.state.packages_to_offer;
            if (this.props.order.services.length > 1) {
                for (let i = 0, l = this.state.packages.length; i < l; i++) {
                    let c = 0;
                    let p = this.props.order.packages.find((_package) => {
                        return _package === this.state.packages[i].id;
                    });
                    if (!p) {
                        for (let x = 0, y = this.props.order.services.length; x < y; x++) {
                            c += this.state.packages[i].services.find((service) => {
                                return service.id === this.props.order.services[x]
                            }) ? 1 : 0;
                        }
                        if (c > 1 && !packages_to_offer.find((_package) => {
                                return _package === this.state.packages[i].id
                            })) {
                            packages_to_offer.push(this.state.packages[i]);
                        }
                    }
                }
            }
            this.setState({
                package_offering_modal: packages_to_offer.length > 0,
                packages_to_offer: packages_to_offer,
            });
        }
    }

    getService(id) {
        return this.state.services.find((service) => {
            return service.id === id;
        });
    }

    selectAppointment(appointment) {
        this.props.calendarActions.setSelectedAppointment(appointment);
        this.props.orderActions.setDatetime(moment(appointment.start_datetime));
        this.props.orderActions.setInspectors(appointment.users.map((inspector) => { return { id: inspector, requested: false } }));

        this.props.calendarActions.setServicesChanged(false);
        this.props.setNavLoading(true);
        this.setState({inspector_conflict_modal: false});

        this.props.calendarActions.save()
            .then(() => {
                this.setState({showModal: true});
                // this.props.router.history.push('/'+this.props.company_key+'/online-scheduler/review?t='+this.props.token + '&office=' + this.props.office_uuid + '&office=' + this.props.office_uuid);
            });
    }

    addRushRER() {
        this.setState({
            adding_rush_rer: true
        });
        this.props.orderActions.setRushRer(true);
        return request.post('/'+this.props.company_key+'/json/online_scheduler/rush_rer')
            .set('X-Auth-Token', this.props.token)
            .type('form')
            .send({value: 1})
            .then(this.props.packageActions.loadPricing)
            .then((res) => {
                this.setState({
                    adding_rush_rer: false
                });
            });
    }

    removeRushRER() {
        this.setState({
            adding_rush_rer: false
        });
        this.props.orderActions.setRushRer(false);
        return request.post('/'+this.props.company_key+'/json/online_scheduler/rush_rer')
            .set('X-Auth-Token', this.props.token)
            .type('form')
            .send({value: 0})
            .then(this.props.packageActions.loadPricing)
            .then((res) => {
                this.setState({
                    adding_rush_rer: false
                });
            });
    }

    setup() {
        if (!this.state.setup) {
            this.loadPackages()
                .then(this.loadServices)
                .then(this.props.packageActions.loadPricing)
                .then(() => {
                    this.props.setNavLoading(false);
                    this.setState({loading:false,setup:true});
                });
        }

        Utils.scrollTop(500);
        this.props.setNavPackages(true);
        if (window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'Online Scheduler',
                eventAction: 'viewed-pricing',
                eventLabel: getAnalyticsEventLabel(this.props.company_key, 'User viewed Online Scheduler Pricing')
            });
        }
    }

    componentDidMount() {
        // run if we got here from an existing state
        if (canSetupOnPageLoad(this.props)) {
            this.setup();
        }
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        // Clean up the event listener when the component unmounts
        window.removeEventListener('resize', this.handleResize);
      }

    componentWillReceiveProps(nextProps) {
        // run if we got here from a new state (i.e. new page load)
        if (canSetupOnExistingState(this.props, nextProps)) {
            this.setup();
        }
    }

    handleResize = () => {
        this.setState({
          isMobile: window.innerWidth < 800,
        });
      };

    getNext(){

        const limit = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT')
            ? this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT_VALUE')
            : 0;

        if(app_state.pac_enabled && !this.hasPacSelected() && this.isPacEligible() && !this.props.settings.getSettingBool('PAC_HIDE_ON_ONLINE_SCHEDULER')){
            this.setState({
                pac_confirmation: true
            });
            return;
        }

        this.save();
        if (!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST')) {
            if (this.props.order.inspectors.length > 0) {
                this.props.calendarActions
                    .validateInspectorServices(limit,
                        this.props.order.duration,
                        this.props.order.inspectors.map((inspector) => { return inspector.id }),
                        this.props.order.services,
                        this.props.order.postal_code
                    )
                    .then((res) => {
                        console.log("Response", res.status);
                        console.log("Response1 ", res);
                        if (res.status === 'ok') {
                            this.setState({showModal: true});
                        }
                    });
            }
            // this.setState({showModal: true})
        }
    }

    getPayAtCloseService(){
        const {services} = this.state;

        let service = services.filter((service) => service.is_pac);

        if(!service || !service.length){
            return {};
        }

        return service[0];
    }

    addPayAtClose(){
        const service = this.getPayAtCloseService();

        if(!service || !service.id){
            return;
        }

        this.addService(service);
    }

    cancelPayAtClose(pacRemovedBecauseDisqualified = false){
        const service = this.getPayAtCloseService();

        if(!service || !service.id){
            return;
        }

        this.removeService(service, pacRemovedBecauseDisqualified);
    }

    verifyPacEligibility(){
        if (this.hasPacSelected() && !this.isPacEligible()) {
            this.cancelPayAtClose(true);
        }
    }

    isPacEligible(){
        const {order, settings} = this.props;

        if(!order || !order.services || !order.services.length || order.buyers_agent?.pac_opt_out || order.sellers_agent?.pac_opt_out){
            return false;
        }

        return order.pricing.pac_eligible;
    }

    hasPacSelected(){
        const {order} = this.props;
        const service = this.getPayAtCloseService();

        if(!order || !order.services || !order.services.length){
            return false;
        }

        if(!service || !service.id){
            return false;
        }

        return order.services.includes(service.id);
    }

    handlePacConfirmationCancel(){
        this.setState({
            pac_confirmation: false
        });
    }

    toggleClose =() =>{
        this.setState({showModal: false})
    }

    render() {
        let content = null;

        const order = this.props.order;
        const pacServiceUuid = app_state.pac_service_uuid;
        const orderHasNonPacServices = order && order.services && order.services.filter((serviceUuid) => {
            return serviceUuid !== pacServiceUuid;
        }).length > 0;

        return (
            <div>
               {this.state.showModal && !this.state.saving && !this.state.inspector_conflict_modal && !this.state.appointment_not_available_modal &&
                   <PopUpModal {...this.props} toggleClose={this.toggleClose}/>}
                {!this.props.app.office_loading && !this.props.app.order_loading ? (
                    <FixedSideBar
                        {...this.props}
                        applyCoupon={this.props.packageActions.applyCoupon}
                        removeService={this.removeService}
                        removePackage={this.removePackage}

                        settings={this.props.settings}
                        packages={this.state.packages}
                        currentPackages={this.props.order.packages}
                        pricingServices={this.props.order.pricing.services}
                    />
                ) : null}
                <Container {...this.props}>
                    {/*FlexFund banner.*/}
                    {/* {app_state.pac_enabled ? (
                        this.hasPacSelected() ? (
                            <div className={'alert alert-success'}>
                                <div className={'mb-3'}>You have chosen to pay for your home inspection when you close on your home. This means you HAVE NOT paid for your inspection yet but will pay for it during the closing process.</div>
                                <div className={'text-center'}>
                                    <button className={'btn btn-danger btn-xs'} onClick={() => this.cancelPayAtClose()}>Never mind</button>
                                </div>
                            </div>
                        ) : (
                            (this.isPacEligible() && !this.props.settings.getSettingBool('PAC_HIDE_ON_ONLINE_SCHEDULER')) ? (
                                <div className={'alert alert-info'}>
                                    <div className={'d-flex-justify'}>
                                        <b>Did you know?</b>
                                    </div>
                                    <div className={'mb-3'}>This inspection qualifies you to pay for your home inspection at closing, allowing you to bundle the cost of your home inspection AND other add-on services alongside your closing costs.</div>
                                    <div className={'text-center'}>
                                        <button className={'btn btn-primary btn-xs'} onClick={() => this.addPayAtClose()}>Let's do it!</button>
                                    </div>
                                </div>
                            ) : null
                        )
                    ) : null} */}

                    {this.state.loading && <div className="text-center"><i className={'fas fa-spinner-third fa-spin fa-2x'}/></div>}
                    {!this.state.loading && this.state.services.length > 0 && this.state.packages.length > 0
                    && <PackagesView packages={this.state.packages}
                                     currentPackages={this.props.order.packages}
                                     addPackage={this.addPackage}
                                     removePackage={this.removePackage}
                                     services={this.state.services}
                                     pricingServices={this.props.order.pricing.services}
                                     addingPackage={this.state.adding_package}
                                     hideFees={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_ALL_FEES') || parseInt(this.props.settings.getSettingValue('PAC_SUBMISSION_FEE')) > 0}
                                     hideTaxes={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_DO_NOT_SHOW_TAX_IN_PACKAGES')}
                                     rerRushable={this.props.settings.getSettingBool('RER_RUSH_ENABLED')}
                                     rushRer={this.props.order.rush_rer}
                                     rerRushAmount={this.props.hasOwnProperty('rer_rush_amount') ? this.props.rer_rush_amount : 0}
                                     addingRushRer={this.state.adding_rush_rer}
                                     addRushRer={this.addRushRER}
                                     removeRushRer={this.removeRushRER}
                                     settings={this.props.settings}
                                     save={this.save}
                                     saving={this.state.saving}
                                     hasPacSelected={this.hasPacSelected}
                                     addPayAtClose={this.addPayAtClose}
                                     canclePayAtClose={this.cancelPayAtClose}
                                     isPacEligible={this.isPacEligible}/>}
                    {!this.state.loading && this.state.services.length > 0
                    && <ServicesView services={this.state.services}
                                     packages={this.state.packages}
                                     currentServices={this.props.order.services}
                                     currentPackages={this.props.order.packages}
                                     pricingServices={this.props.order.pricing.services}
                                     settings={this.props.settings}
                                     addService={this.addService}
                                     removeService={this.removeService}
                                     addingService={this.state.adding_service}
                                     hideFees={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_ALL_FEES') || parseInt(this.props.settings.getSettingValue('PAC_SUBMISSION_FEE')) > 0}
                                     hideTaxes={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_DO_NOT_SHOW_TAX_IN_PACKAGES')}
                                     rerRushable={this.props.settings.getSettingBool('RER_RUSH_ENABLED')}
                                     rushRer={this.props.order.rush_rer}
                                     rerRushAmount={this.props.hasOwnProperty('rer_rush_amount') ? this.props.rer_rush_amount : 0}
                                     addingRushRer={this.state.adding_rush_rer}
                                     addRushRer={this.addRushRER}
                                     removeRushRer={this.removeRushRER}
                                     hasPacSelected={this.hasPacSelected}
                                     addPayAtClose={this.addPayAtClose}
                                     canclePayAtClose={this.cancelPayAtClose}
                                     isPacEligible={this.isPacEligible}/>}
                    {!this.state.loading && this.props.order.pricing && this.props.order.pricing.hasOwnProperty('additional') && this.props.order.pricing.additional.hasOwnProperty('total') && parseFloat(this.props.order.pricing.additional.total) !== 0.00
                    && !this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_ALL_FEES')
                    && <AdditionalFeesView additional={this.props.order.pricing.additional}/>}
                    {!this.state.loading && this.state.services.length === 0 && <div className={'alert alert-warning alert-tight'}>
                        <h4>We're sorry</h4>
                        <p>We don't have any services to offer at this time through our online scheduling form. For help scheduling your inspection please
                            call us at <a href={"tel:"+app_state.office_phone}>{app_state.office_phone}</a>.</p>
                    </div>}
                    {
                    // !this.state.loading && this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_COUPON_LIMIT') > 0 && this.props.order.services.length > 0 && (!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_COUPON_AFTER_PACKAGE_ADDED') || this.props.order.packages.length === 0) && <div className="row" style={{marginTop:'25px'}}>
                    //     <div className="col-md-12">
                    //         <Coupon order={this.props.order} apply={this.props.packageActions.applyCoupon}/>
                    //     </div>
                    // </div>
                    }
                    {
                    !this.state.loading && this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_COUPON_LIMIT') > 0 && this.props.order.services.length > 0 && (!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_COUPON_AFTER_PACKAGE_ADDED') || this.props.order.packages.length === 0)  && this.state.isMobile  && <div className="row" style={{marginTop:'25px'}}>
                        <div className="col-md-12">
                            <Coupon type={'packages'} order={this.props.order} apply={this.props.packageActions.applyCoupon}/>
                        </div>
                    </div>
                    }
                    {!this.state.loading &&
                        <div className="col-md-12 action-buttons-space">
                            <div className="btn-toolbar" style={{display:'block'}}>
                                <BackButton className="back-btn-class" back={this.prev}/>
                                <NextButton className={this.state?.saving || !orderHasNonPacServices || (this.props.order.packages.length === 0 && this.props.order.services.length === 0) ? this.state.saving ? 'hide-button'  : 'next-btn-class-dis'  : 'next-btn-class'} next={() => this.getNext()} disabled={this.state.saving || !orderHasNonPacServices || (this.props.order.packages.length === 0 && this.props.order.services.length === 0)}/>
                                <button className={this.state?.saving ? 'next-btn-class show-button' : 'hide-button'} >&nbsp;<i className="fa fa-spinner fa-spin"></i>&nbsp;</button>
                            </div>
                    </div>}
                </Container>

                {this.state.pac_confirmation && !this.props.settings.getSettingBool('PAC_HIDE_ON_ONLINE_SCHEDULER') &&
                    <PacConfirmationModal
                        onConfirm={() => {
                            this.save()
                            if (!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST')) {
                                this.handlePacConfirmationCancel()
                                this.setState({showModal: true})
                            }
                        } }
                        onCancel={() => this.handlePacConfirmationCancel()}
                    />
                }

                {/* Rendering the validation modal after api call */}
                {this.state.inspector_conflict_modal && <PackageServiceInspectorAppointmentConflictModal
                    datetime={this.props.order.datetime}
                    services={this.props.order.services}
                    appointments={this.state.substitute_appointments}
                    selectAppointment={this.selectAppointment}
                    users={this.props.users}
                    settings={this.props.settings}
                    close={() => {
                        this.setState({inspector_conflict_modal:false})
                    }}
                />}

                {this.state.appointment_not_available_modal &&
                  <PackageServiceInspectorAppointmentConflictModal
                    order={this.props.order}
                    close={() => {
                      this.setState({ appointment_not_available_modal: false });
                    }}
                    settings={this.props.settings}
                    selectAppointment={this.selectAppointment}
                    datetime={this.props.order.datetime}
                    users={this.props.users}
                    appointments={this.state.substitute_appointments}
                    office_phone={this.props.office_phone}
                  />}

                {this.state.package_offering_modal && <PackageOfferingForTwoOrMoreServicesModal
                    packages={this.state.packages_to_offer}
                    addPackage={this.addPackage}
                    hideFees={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_HIDE_ALL_FEES') || parseInt(this.props.settings.getSettingValue('PAC_SUBMISSION_FEE')) > 0}
                    close={() => {
                        this.setState({package_offering_modal:false,packages_to_offer:[]})
                    }}
                />}
            </div>);
    }
}

/**
 *
 */
export default withRouter(connect(
    (state, ownProps) => {
        return { router: ownProps, ...state.Packages, ...state.Office, order: state.Order, calendar: state.Calendar, nav: state.Nav, app: state.App }
    },
    (dispatch) => {
        return {
            packageActions: bindActionCreators(PackagesActions, dispatch),
            orderActions: bindActionCreators(OrderActions, dispatch),
            officeActions: bindActionCreators(OfficeActions, dispatch),
            calendarActions: bindActionCreators(CalendarActions, dispatch),
            setNavPackages: (value) => dispatch(setNavPackages(value)),
            setNavLoading: (value) => dispatch(setNavLoading(value))
        }
    }
)(PackagesContainer))