import React, { Component } from 'react'
import { asyncContainer, Typeahead, Highlighter } from 'react-bootstrap-typeahead'
import { app_state } from '../app_data.jsx'
import request from 'superagent'
import PersonEditForm from './PersonEditForm.jsx'
import PersonViewForm from './PersonViewForm.jsx'
import { Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper } from '@material-ui/core';
import "./contactinfo.css";

const AsyncTypeahead = asyncContainer(Typeahead);

const styles = {
    autocomplete: {
      minWidth: '104%',
    },
    inputLabel: {
        fontSize: 22,
        background: "white",
        paddingRight: 5,
        position: "absolute",
        top: "-3px",
        fontFamily: 'Source Sans Pro',
    //   '&.Mui-focused': { // Add the focused style here
    //     color: '#0F75BC',
    //    },
    },
    input: {
        style: {
            fontSize: 16,
        }
      }
};

const ResetIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" fill="rgb(117, 117, 117)" viewBox="0 -960 960 960" width="20"><path d="m448-326 112-112 112 112 43-43-113-111 111-111-43-43-110 112-112-112-43 43 113 111-113 111 43 43ZM120-480l169-239q13-18 31-29.5t40-11.5h420q24.75 0 42.375 17.625T840-700v440q0 24.75-17.625 42.375T780-200H360q-22 0-40-11.5T289-241L120-480Zm75 0 154 220h431v-440H349L195-480Zm585 0v-220 440-220Z"/></svg>
)
export class ContactView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <PersonViewForm {...this.props}>
                    {(!this.props.hasOwnProperty('compact') || !this.props.compact) && <div className="form-group">
                        <label className="col-sm-2 col-md-4 control-label">Company:</label>
                        <div className="col-sm-10 col-md-8">
                            <p className="form-control-static">{this.props.company}</p>
                        </div>
                    </div>}
                    {this.props.hasOwnProperty('compact') && this.props.compact && <div>{this.props.company}</div>}
                </PersonViewForm>
            </div>
        )
    }
}

export class ContactEditView extends Component {
    constructor(props) {
        super(props);

        this.updateFirstName = this.updateFirstName.bind(this);
        this.updateLastName = this.updateLastName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateMobilePhone = this.updateMobilePhone.bind(this);
        this.updateWorkPhone = this.updateWorkPhone.bind(this);
        this.updateHomePhone = this.updateHomePhone.bind(this);
        this.updateWorkFax = this.updateWorkFax.bind(this);
        this.updateHomeFax = this.updateHomeFax.bind(this);
        this.updateAddress1 = this.updateAddress1.bind(this);
        this.updateAddress2 = this.updateAddress2.bind(this);
        this.updateCity = this.updateCity.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updatePostalCode = this.updatePostalCode.bind(this);
        this.updateCompany = this.updateCompany.bind(this);
    }

    updateFirstName(e) {
        this.props.updateTextField(e, this.props.type, 'first', this.props.validation.hasOwnProperty('first'));
    }

    updateLastName(e) {
        this.props.updateTextField(e, this.props.type, 'last', this.props.validation.hasOwnProperty('last'));
    }

    updateEmail(e) {
        this.props.updateTextField(e, this.props.type, 'email', this.props.validation.hasOwnProperty('email'));
    }

    updateMobilePhone(e) {
        this.props.updateTextField(e, this.props.type, 'mobile_phone', this.props.validation.hasOwnProperty('mobile_phone'));
    }

    updateWorkPhone(e) {
        this.props.updateTextField(e, this.props.type, 'work_phone', this.props.validation.hasOwnProperty('work_phone'));
    }

    updateHomePhone(e) {
        this.props.updateTextField(e, this.props.type, 'home_phone', this.props.validation.hasOwnProperty('home_phone'));
    }

    updateWorkFax(e) {
        this.props.updateTextField(e, this.props.type, 'work_fax', this.props.validation.hasOwnProperty('work_fax'));
    }

    updateHomeFax(e) {
        this.props.updateTextField(e, this.props.type, 'home_fax', this.props.validation.hasOwnProperty('home_fax'));
    }

    updateAddress1(e) {
        this.props.updateTextField(e, this.props.type, 'address1', this.props.validation.hasOwnProperty('address1'));
    }

    updateAddress2(e) {
        this.props.updateTextField(e, this.props.type, 'address2', this.props.validation.hasOwnProperty('address2'));
    }

    updateCity(e) {
        this.props.updateTextField(e, this.props.type, 'city', this.props.validation.hasOwnProperty('city'));
    }

    updateState(e) {
        this.props.updateTextField(e, this.props.type, 'state', this.props.validation.hasOwnProperty('state'));
    }

    updatePostalCode(e) {
        this.props.updateTextField(e, this.props.type, 'postal_code', this.props.validation.hasOwnProperty('postal_code'));
    }

    updateCompany(e) {
        this.props.updateTextField(e, this.props.type, 'company', this.props.validation.hasOwnProperty('company'));
    }

    render() {
        return (
            <div>
                <div className="form-horizontal container-left">
                    <div style={{paddingLeft: '2%', fontSize:'18px', fontWeight: '600',display:'flex', justifyContent: 'space-between'}}>
                        <div>
                            Add {this.props.typeName ? this.props.typeName : 'Contact'}
                        </div>
                       <div style={{paddingRight: '20%', cursor: "pointer"}} onClick={this.props.remove}>
                                  <span style={{fontSize: '16px', color: '#757575'}}> Delete contact </span>   
                                  <span style={{position: 'relative', top: '5px'}}><ResetIcon/></span>
                        </div>
                    </div>
                    <PersonEditForm
                        key={this.props.type}
                        ref_key={this.props.type}
                        setRef={this.props.setRef}
                        fields={this.props.fields}
                        first={{value:this.props.first,update:this.updateFirstName}}
                        last={{value:this.props.last,update:this.updateLastName}}
                        email={{value:this.props.email,update:this.updateEmail}}
                        mobile_phone={{value:this.props.mobile_phone,update:this.updateMobilePhone}}
                        work_phone={{value:this.props.work_phone,update:this.updateWorkPhone}}
                        home_phone={{value:this.props.home_phone,update:this.updateHomePhone}}
                        work_fax={{value:this.props.work_fax,update:this.updateWorkFax}}
                        home_fax={{value:this.props.home_fax,update:this.updateHomeFax}}
                        address1={{value:this.props.address1,update:this.updateAddress1}}
                        address2={{value:this.props.address2,update:this.updateAddress2}}
                        city={{value:this.props.city,update:this.updateCity}}
                        state={{value:this.props.state,update:this.updateState}}
                        postal_code={{value:this.props.postal_code,update:this.updatePostalCode}}
                        company={{value:this.props.company,update:this.updateCompany}}
                        validation={this.props.validation}
                        save={this.props.save}
                        states={this.props.states}/>
                </div>
                {/* <div className="text-center" style={{marginTop:'5px'}}>
                    <button type="button" className="btn btn-success btn-xs"
                            onClick={this.props.save}>
                        <i className="fas fa-save"/> Save
                    </button>
                    &nbsp;
                    <button type="button" className="btn btn-danger btn-xs"
                            onClick={this.props.cancel}>
                        <i className="fas fa-ban"/> Cancel
                    </button>
                </div> */}
            </div>
        )
    }
}

export class ContactSearchView extends Component {
    constructor(props) {
        super(props);

        // this.renderMenuItemChildren = this.renderMenuItemChildren.bind(this);
        // this.handleSelection = this.handleSelection.bind(this);
        // this.handleSearch = this.handleSearch.bind(this);
        // this.handleInputChange = this.handleInputChange.bind(this);

        this.state = {
            isLoading: false,
            options: [{display: "Add new contact", state: 1}],
            query: ''
        };
    }

    // renderMenuItemChildren(option, props, index) {
    //     return <div>
    //         <Highlighter key="name" search={props.text}>
    //             {option.display}
    //         </Highlighter>
    //         {option.company && option.company !== '' && option.company !== 'n/a' && <span style={{fontSize:'85%'}}> ({option.company})</span>}
    //     </div>;
    // }

    // handleSelection(items) {
    //     this.props.select(items[0]);
    // }

    // handleInputChange(query) {
    //     this.setState({query});
    // }

    // handleSearch(query) {
    //     this.setState({isLoading: true});
    //     request.get('/'+app_state.company_key+'/json/online_scheduler/search_contacts?query='+query+'&type='+this.props.type.id)
    //         .set('X-Auth-Token', app_state.token)
    //         .then((res) => {
    //             this.setState({
    //                 isLoading: false,
    //                 options:  res.body
    //             });
    //         });
    //     }
        
    

    // componentDidMount() {
    //     setTimeout(() => {
    //         this.ref_typeahead.getInstance().focus();
    //     }, this.props.focusDelay);
    // }

    onChangeFunc(e, value){
        const val1 = {
            type: this.props.type
        };
        const finalValue = {...val1,...value }
        this.props.select(finalValue);
    }
   
    handleSearchFunc(e){
        this.setState({isLoading: true});
            request.get('/'+app_state.company_key+'/json/online_scheduler/search_contacts?query='+e+'&type='+this.props.type.id)
            .set('X-Auth-Token', app_state.token)
            // .on('error', (err) => errorHandler(err, dispatch))
            .then((res) => {
                // if(res.body != []){
                    let data = [...res.body,{
                        display : "Add new contact",
                        state: 1,
                    }];
                    this.setState({
                        isLoading: false,
                        options: data,
                        query: e
                    });
                // }
            });
    }
    


    render() {

        return (
            <div className="col-lg-10 search-contact-container">
                <Autocomplete
                    id="combo-box-demo"
                    freeSolo
                    options={this.state.options}
                    getOptionLabel={(option) => {
                        if(option?.display){
                            return option?.display;
                        }
                    }}
                    filterOptions={(options) => options}
                    style={styles.autocomplete}
                    renderOption={(option, { selected }) => {
                        if(option?.display == "Add new contact"){
                            return (
                                <li style={{color: "#0F75BC"}}>
                                  {option?.display}
                                </li>
                              );
                        }
                        else{
                            return (
                                <li>
                                  {option?.display}
                                </li>
                              );
                        }
                      }}
                    // onInputChange={this.handleInputChange}
                    renderInput={(params) => 
                    <TextField {...params} 
                    label={'Search for your '+this.props.type.name} 
                    variant="outlined"
                    InputProps={{ ...params.InputProps, ...styles.input }}
                    InputLabelProps={{
                        style: styles.inputLabel,
                        shrink: true
                    }}
                    onKeyUp={(ev) => {
                        if (ev.target.value !== "" || ev.target.value !== null) {
                          this.handleSearchFunc(ev.target.value);
                        }
                      }}
                    value={this.state.query}
                    />}
                    onChange={(e, value) => this.onChangeFunc(e, value)}
                    PaperComponent={(props) => (
                        <Paper {...props} style={{
                            fontSize: "16px"
                        }} />
                      )}
                />
                {/* <AsyncTypeahead
                    {...this.state}
                    id={'contact-search-'+this.props.type.id}
                    ref={(el) => { this.ref_typeahead = el }}
                    minLength={2}
                    labelKey="display"
                    onInputChange={this.handleInputChange}
                    onSearch={this.handleSearch}
                    onChange={this.handleSelection}
                    placeholder={'Search for your '+this.props.type.name}
                    useCache={true}
                    renderMenuItemChildren={this.renderMenuItemChildren}
                    filterBy={['first','last','display','email','mobile_phone']}
                /> */}
            </div>
        )
    }
}

export class AddContact extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="contact-form">
                <div className="left-container-padding" style={{margin:'0',padding:'3px', paddingBottom: '2%', fontSize:'18px', fontWeight: '600'}}>
                    Have more contacts? Just add them below!
                </div>
                <div className="">
                    <div className='left-container-padding'>
                        <button type="button" className="btn btn-default btn-xs"
                            style={{padding: "8px 16px"}}
                            onClick={this.props.search}>
                            <i className="far fa-plus" style={{marginRight: "6px"}} /> Add contact
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.save = this.save.bind(this);
    }

    save()
    {
        this.props.save()
            .then(null, (e) => null)
    }

    render() { 
        return (
            <div className="contact-form">
                <div className="panel">
                    {/* <div className="panel-heading">
                        <div>
                            {this.props.flags.edit ? 'Edit ' : ''}
                            {this.props.flags.add || this.props.flags.search ? 'Add ' : ''}
                            {this.props.data.type ? this.props.data.type.name : 'Contact'}
                        </div>
                    </div> */}
                    <div className="panel-body">
                        {this.props.flags.search
                        &&
                        <div className="row">
                                <div className="left-container-padding" style={{ margin: '8px', padding: '3px', paddingBottom: '2%', fontSize: '18px', fontWeight: '600' }}>
                                    Have more contacts? Just add them below!
                                </div>
                            <div className="addContact-container" style={{float: 'none' }}>
                                {/* <strong>contact is this?</strong>
                                <select className="form-control input-sm"
                                        style={{marginBottom:'5px'}}
                                        onChange={this.props.setType}>
                                    {this.props.types.map((type, index) => {
                                        return (
                                            <option key={index} value={type.id}>{type.name}</option>
                                        )
                                    })}
                                </select> */}
                                <FormControl variant="outlined" className='contact-input-container' style={{ marginTop: 14, marginLeft: 22, marginBottom: 25 }}>
                            <InputLabel style={{ fontSize: 22,background: "white",paddingRight: 5,position: "absolute",top: "-3px",fontFamily: 'Source Sans Pro',}} shrink>What type of contact is this?</InputLabel>
                            <Select label="What type of contact is this? "
                                style={{fontSize: "15px"}}
                                onChange={this.props.setType}
                                MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                  }}
                                >
                                {this.props.types.map((type, index) => {
                                    if(type.name != "--please select---")
                                    return (
                                        <MenuItem  style={{fontSize: "16px"}} key={index} value={type.id}>{type.name}</MenuItem>
                                    )
                                })}
                            </Select>
                            {/* <FormHelperText>Select a month</FormHelperText> */}
                        </FormControl>
                                {this.props.data.type && <ContactSearchView 
                                                data={this.props.data}
                                                type={this.props.data.type}
                                                 select={this.props.select}
                                                 add={this.props.add}
                                                 focusDelay={500}/>}
                                <div className="btn-toolbar text-center" style={{marginTop: '6px'}}>
                                    {/* {this.props.data.type && <button type="button" className="btn btn-primary btn-xs"
                                            style={{float: 'none'}}
                                            title={"Add a new " + this.props.data.type.name + " profile"}
                                            onClick={this.props.add}>
                                        <i className="far fa-plus-circle"/> New
                                    </button>} */}
                                    {/* <button type="button" className="btn btn-danger btn-xs"
                                                                    style={{float: 'none'}}
                                                                    onClick={this.props.none}>
                                        <i className="fa fa-remove"/> Cancel
                                    </button> */}
                                </div>
                            </div>
                        </div>}

                        {this.props.data !== null && !this.props.flags.search && !this.props.flags.edit && !this.props.flags.add
                        && <ContactEditView {...this.props.data}
                        fields={this.props.fields}
                        // type={'contact_type_'+this.props.data.type.id}
                        setRef={this.props.setRef}
                        isNew={false}
                        updateTextField={this.props.update}
                        validation={this.props.validation}
                        save={this.save}
                        cancel={this.props.cancel}
                        states={this.props.states}
                        // typeName={this.props.data.type.name}
                        remove={this.props.remove}/>
                        }
                        {this.props.flags.edit && <ContactEditView {...this.props.data}
                                                                         fields={this.props.fields}
                                                                        //  type={'contact_type_'+this.props.data.type.id}
                                                                         setRef={this.props.setRef}
                                                                         isNew={false}
                                                                         updateTextField={this.props.update}
                                                                         validation={this.props.validation}
                                                                         save={this.save}
                                                                         cancel={this.props.cancel}
                                                                         states={this.props.states}/>
                        }
                        {this.props.flags.add && <ContactEditView {...this.props.data}
                                                                        fields={this.props.fields}
                                                                        // type={'contact_type_'+this.props.data.type.id}
                                                                        setRef={this.props.setRef}
                                                                        isNew={true}
                                                                        updateTextField={this.props.update}
                                                                        validation={this.props.validation}
                                                                        save={this.save}
                                                                        cancel={this.props.cancel}
                                                                        states={this.props.states}/>
                        }
                    </div>
                    {/* {this.props.data !== null && !this.props.flags.search && !this.props.flags.edit && !this.props.flags.add
                    && <div className="panel-footer">
                        <div className="btn-toolbar text-center">
                            <button type="button" className="btn btn-default btn-xs"
                                    style={{float:'none'}}
                                    onClick={this.props.edit}>
                                <i className="fas fa-pencil"/> Edit
                            </button>
                            
                        </div>
                    </div>} */}
                    {/* <button type="button" className="btn btn-danger btn-xs"
                                    style={{float:'none'}}
                                    onClick={this.props.remove}>
                                <i className="fa fa-remove"/> Remove {this.props.data.type ? this.props.data.type.name : 'Contact'}
                            </button> */}
                </div>
            </div>
        )
    }
}